import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Headerコンポーネントに対応するCSSファイルをインポート
import logoImage from "../media/miruru_logo.png"

const Header: React.FC = () => {
  return (
    <header className="header">
      <Link to="/">
        <img src={logoImage} alt=""  className="logo"/>
      </Link>
    </header>
  );
};

export default Header;