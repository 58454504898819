import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import BarcodeReader from './components/BarcodeReader';
import { IgrButtonModule, IgrButton } from 'igniteui-react';
import 'igniteui-webcomponents/themes/light/bootstrap.css';
// import './App.css';
IgrButtonModule.register();

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <h3>RUの交換を実施します。<br />
新しいRUの一次元バーコードを<br />
読み取ってください。</h3>
      <BarcodeReader />
      <div className='readingResult'>
        <h3>読み取った文字列はこちら。</h3>
        <p id="result"></p>
      </div>
      {/* <IgrButton /> */}
      <IgrButton variant="contained">
        <span>
          シリアルをアップロード
        </span>
      </IgrButton>
    </Router>
  );
};

export default App;
